import React, { createContext, useContext } from "react";
import { AccrualAndOperationsExpenses, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getAccrualAndOperationsExpensesGraphqlMutationOptions, MUTATE_ACCRUAL_AND_OPERATIONS_EXPENSES } from "./accrualAndOperationsExpensesMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";
import { useMessageQueueContext } from "../../messageQueue/messageQueueContext";

export interface AccrualAndOperationsExpensesMutationsContext {
    mutateAccrualAndOperationsExpenses: (accrualAndOperationsExpenses: AccrualAndOperationsExpenses, onTicketSubscribed: (documentId: Guid, variables: any) => void) => Promise<void>;
}

const AccrualAndOperationsExpensesMutationsContext = createContext<AccrualAndOperationsExpensesMutationsContext>(null as unknown as AccrualAndOperationsExpensesMutationsContext);

export const AccrualAndOperationsExpensesMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const messageQueueContext = useMessageQueueContext();
    const [accrualAndOperationsExpensesMutation] = useMutation(MUTATE_ACCRUAL_AND_OPERATIONS_EXPENSES);

    const handleAccrualAndOperationsExpensesMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateAccrualAndOperationsExpenses.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
    }

    const mutateAccrualAndOperationsExpenses = async (accrualAndOperationsExpenses: AccrualAndOperationsExpenses, onTicketSubscribed: (documentId: Guid, variables: any) => void): Promise<void> => {
        const options = getAccrualAndOperationsExpensesGraphqlMutationOptions(accrualAndOperationsExpenses);
        try {
            const result = await accrualAndOperationsExpensesMutation(options);
            return handleAccrualAndOperationsExpensesMutationResult(result, options.variables, onTicketSubscribed);
        } catch (error) {
            messageQueueContext.addMessage({})
            throw error;
        }
    }

    const accrualAndOperationsExpensesMutationsContext: AccrualAndOperationsExpensesMutationsContext = {
        mutateAccrualAndOperationsExpenses
    };

    return (
        <AccrualAndOperationsExpensesMutationsContext.Provider value={accrualAndOperationsExpensesMutationsContext}>
            {children}
        </AccrualAndOperationsExpensesMutationsContext.Provider>
    );
}

export const useAccrualAndOperationsExpensesMutationsContext = (): AccrualAndOperationsExpensesMutationsContext => {
    return useContext(AccrualAndOperationsExpensesMutationsContext);
}
