import React, { createContext, useContext } from "react";
import { ChangeOrderSubContractor, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getChangeOrderSubContractorsGraphqlMutationOptions, MUTATE_CHANGE_ORDER } from "./changeOrderSubContractorMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";
import { useMessageQueueContext } from "../../messageQueue/messageQueueContext";

export interface ChangeOrderSubContractorMutationsContext {
    mutateChangeOrderSubContractor: (accout: ChangeOrderSubContractor, onTicketSubscribed: (documentId: Guid, variables: any) => void) => void;
}

const ChangeOrderSubContractorMutationsContext = createContext<ChangeOrderSubContractorMutationsContext>(null as unknown as ChangeOrderSubContractorMutationsContext);

export const ChangeOrderSubContractorMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const messageQueueContext = useMessageQueueContext();
    const [changeOrderSubContractorMutation] = useMutation(MUTATE_CHANGE_ORDER);

    const handleChangeOrderSubContractorMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateChangeOrderSubContractor.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                        onTicketSubscribed(ticket.parentDocumentId, variables);
                    }
                }
            }
        });
    }

    const mutateChangeOrderSubContractor = (changeOrderSubContractor: ChangeOrderSubContractor, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        const options = getChangeOrderSubContractorsGraphqlMutationOptions(changeOrderSubContractor);
        changeOrderSubContractorMutation(options).then((result) => handleChangeOrderSubContractorMutationResult(result, options.variables, onTicketSubscribed)).catch((error) => messageQueueContext.addMessage({}));
    }

    const changeOrderSubContractorMutationsContext: ChangeOrderSubContractorMutationsContext = {
        mutateChangeOrderSubContractor
    };

    return (
        <ChangeOrderSubContractorMutationsContext.Provider value={changeOrderSubContractorMutationsContext}>
            {children}
        </ChangeOrderSubContractorMutationsContext.Provider>
    );
}

export const useChangeOrderSubContractorMutationsContext = (): ChangeOrderSubContractorMutationsContext => {
    return useContext(ChangeOrderSubContractorMutationsContext);
}
