import React, { createContext, useContext } from "react";

export interface AppMessage {
  message?: string;
  severity?: "error" | "warning" | "info" | "success" | undefined;
}

export interface MessageQueueContext {
  messages: AppMessage[];
  addMessage: (message: AppMessage) => void;
  shiftMessage: () => void;
  children?: React.ReactNode;
}

const MessageQueueContext = createContext<MessageQueueContext>(null as unknown as MessageQueueContext);

type Props = {
  children?: React.ReactNode;
};

export const MessageQueueContextProvider: React.FC<Props> = ({ children }) => {
  const [messages, setMessages] = React.useState<AppMessage[]>([]);
  const addMessage = (message: AppMessage) => {
    setMessages([...messages, message]);
  };

  const shiftMessage = () => {
    const newMessages = messages.slice();
    newMessages.shift();
    setMessages(newMessages);
  };

  const contextValue: MessageQueueContext = {
    addMessage,
    shiftMessage,
    messages,
  };

  return <MessageQueueContext.Provider value={contextValue}>{children}</MessageQueueContext.Provider>;
};

export const useMessageQueueContext = (): MessageQueueContext => {
  return useContext(MessageQueueContext);
};
