import React, { createContext, useContext } from "react";
import { Contract, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getContractGraphqlMutationOptions, MUTATE_CONTRACT } from "./contractMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";
import { useMessageQueueContext } from "../../messageQueue/messageQueueContext";

export interface ContractMutationsContext {
    mutateContract: (contract: Contract, onTicketSubscribed: (documentId: Guid, variables: any) => void) => void;
}

const ContractMutationsContext = createContext<ContractMutationsContext>(null as unknown as ContractMutationsContext);

export const ContractMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const messageQueueContext = useMessageQueueContext();
    const [contractMutation] = useMutation(MUTATE_CONTRACT);

    const handleMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateContract.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
    }

    const mutateContract = (contract: Contract, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        const options = getContractGraphqlMutationOptions(contract);
        contractMutation(options).then((result) => handleMutationResult(result, options.variables, onTicketSubscribed)).catch((error) => messageQueueContext.addMessage({}));
    }

    const contractMutationsContext: ContractMutationsContext = {
        mutateContract
    };

    return (
        <ContractMutationsContext.Provider value={contractMutationsContext}>
            {children}
        </ContractMutationsContext.Provider>
    );
}

export const useContractMutationsContext = (): ContractMutationsContext => {
    return useContext(ContractMutationsContext);
}
