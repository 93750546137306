import React, { createContext, useContext } from "react";
import { Risk, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getRisksGraphqlMutationOptions, MUTATE_RISK } from "./riskMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";
import { useMessageQueueContext } from "../../messageQueue/messageQueueContext";

export interface RiskMutationsContext {
    mutateRisk: (risk: Risk, onTicketSubscribed: (documentId: Guid, variables: any) => void) => void;
}

const RiskMutationsContext = createContext<RiskMutationsContext>(null as unknown as RiskMutationsContext);

export const RiskMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const messageQueueContext = useMessageQueueContext();
    const [riskMutation] = useMutation(MUTATE_RISK);

    const handleRiskMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateRisk.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
    }

    const mutateRisk = (risk: Risk, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        const options = getRisksGraphqlMutationOptions(risk);
        riskMutation(options).then((result) => handleRiskMutationResult(result, options.variables, onTicketSubscribed)).catch((error) => messageQueueContext.addMessage({}));
    }

    const riskMutationsContext: RiskMutationsContext = {
        mutateRisk
    };

    return (
        <RiskMutationsContext.Provider value={riskMutationsContext}>
            {children}
        </RiskMutationsContext.Provider>
    );
}

export const useRiskMutationsContext = (): RiskMutationsContext => {
    return useContext(RiskMutationsContext);
}
