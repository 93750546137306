import { CssBaseline } from "@material-ui/core";
import ReactDOM from "react-dom";
import App from "./App";
import { LanguageProvider } from "./contexts/language/LanguageContext";
import { UserContextProvider } from "./contexts/user/UserContext";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import { MenuProvider } from "./contexts/menu/menuContext";
import { TicketContextProvider } from "./contexts/ticket/ticketContext";
import { ProjectContextProvider } from "./contexts/project/projectContext";
import { AccountContextProvider } from "./contexts/account/accountContext";
import { InvoiceContextProvider } from "./contexts/invoice/invoiceContext";
import { DateFnsProvider } from "./contexts/dateFns/DateFnsProvider";
import { ChangeOrderSubContractorContextProvider } from "./contexts/changeOrderSubContractor/changeOrderSubContractorContext";
import { ChangeOrderLandlordContextProvider } from "./contexts/changeOrderLandlord/changeOrderLandlordContext";
import { UrlContextProvider } from "./contexts/url/urlContext";
import { ThemeContextProvider } from "./contexts/theme/ThemeContext";
import { MaterialTableLanguageContextProvider } from "./contexts/language/MaterialTableLanguageContext";
import { RiskContextProvider } from "./contexts/risk/riskContext";
import { AuthContextProvider } from "./contexts/auth/authContext";
import { UserRoleContextProvider } from "./contexts/userRole/userRoleContext";
import { GraphqlContextProvider } from "./contexts/graphql/graphqlContext";
import { ForecastsContextProvider } from "./contexts/forecast/forecastsContext";
import { ContractContextProvider } from "./contexts/contract/contractContext";
import { AccrualAndOperationsContextProvider } from "./contexts/accrualAndOperations/accrualAndOperationsContext";
import { AccrualAndOperationsExpensesContextProvider } from "./contexts/accrualAndOperationsExpenses/accrualAndOperationsExpensesContext";
import { StateDocumentQueriesContextProvider } from "./contexts/stateDocument/queries/stateDocumentQueriesContext";
import { InterimContextProvider } from "./contexts/interim/InterimContext";
import { CrewResourceContextProvider } from "./contexts/crewResource/crewResourceContext";
import React from "react";
import { CrewListContextProvider } from "./contexts/crewList/crewListContext";
import { WidthContextProvider } from "./contexts/WidthContext";
import { BrowserContextProvider } from "./contexts/browserContext/browserContext";
import { TemplateEngineQueriesContextProvider } from "./contexts/templateEngine/queries/templateEngineQueriesContext";
import { StorageDocumentQueriesContextProvider } from "./contexts/storageDocumentQueries/Queries/storageDocumentQueriesContext";
import { StorageFileContextProvider } from "./contexts/storageFile/storageFileContext";
import { ChangeOrderExpectedContextProvider } from "./contexts/changeOrderExpected/changeOrderExpectedContext";
import { StateDocumentEventsQueriesContextProvider } from "./contexts/StateDocumentEvents/queries/stateDocumentEventsQueriesContext";
import { ChangeOrderCounterClaimContextProvider } from "./contexts/changeOrderCounterClaim/changeOrderCounterClaimContext";
import { ErrorHandlerContextProvider } from "./contexts/errorHandler/errorHandlerContext";
import { TracingHandlerContextProvider } from "./contexts/tracingHandler/tracingHandlerContext";
import { ChangeLogContextProvider } from "./contexts/changeLog/ChangeLogContext";
import { MessageQueueContextProvider } from "./contexts/messageQueue/messageQueueContext";

ReactDOM.render(
  <>
    <BrowserContextProvider>
      <CssBaseline />
      <UrlContextProvider>
        <AuthContextProvider>
          <UserContextProvider>
            <GraphqlContextProvider>
              <ErrorHandlerContextProvider>
                <TracingHandlerContextProvider>
                  <ThemeContextProvider>
                    <MessageQueueContextProvider>
                      <WidthContextProvider>
                        <LanguageProvider>
                          <MaterialTableLanguageContextProvider>
                            <DateFnsProvider>
                              <TemplateEngineQueriesContextProvider>
                                <StorageFileContextProvider>
                                  <StorageDocumentQueriesContextProvider>
                                    <StateDocumentQueriesContextProvider>
                                      <TicketContextProvider>
                                        <StateDocumentEventsQueriesContextProvider>
                                          <ChangeLogContextProvider>
                                            <ProjectContextProvider>
                                              <UserRoleContextProvider>
                                                <CrewListContextProvider>
                                                  <CrewResourceContextProvider>
                                                    <AccountContextProvider>
                                                      <ContractContextProvider>
                                                        <RiskContextProvider>
                                                          <InvoiceContextProvider>
                                                            <ChangeOrderSubContractorContextProvider>
                                                              <ChangeOrderLandlordContextProvider>
                                                                <ChangeOrderExpectedContextProvider>
                                                                  <ChangeOrderCounterClaimContextProvider>
                                                                    <InterimContextProvider>
                                                                      <AccrualAndOperationsExpensesContextProvider>
                                                                        <AccrualAndOperationsContextProvider>
                                                                          <ForecastsContextProvider>
                                                                            <MenuProvider>
                                                                              <App />
                                                                            </MenuProvider>
                                                                          </ForecastsContextProvider>
                                                                        </AccrualAndOperationsContextProvider>
                                                                      </AccrualAndOperationsExpensesContextProvider>
                                                                    </InterimContextProvider>
                                                                  </ChangeOrderCounterClaimContextProvider>
                                                                </ChangeOrderExpectedContextProvider>
                                                              </ChangeOrderLandlordContextProvider>
                                                            </ChangeOrderSubContractorContextProvider>
                                                          </InvoiceContextProvider>
                                                        </RiskContextProvider>
                                                      </ContractContextProvider>
                                                    </AccountContextProvider>
                                                  </CrewResourceContextProvider>
                                                </CrewListContextProvider>
                                              </UserRoleContextProvider>
                                            </ProjectContextProvider>
                                          </ChangeLogContextProvider>
                                        </StateDocumentEventsQueriesContextProvider>
                                      </TicketContextProvider>
                                    </StateDocumentQueriesContextProvider>
                                  </StorageDocumentQueriesContextProvider>
                                </StorageFileContextProvider>
                              </TemplateEngineQueriesContextProvider>
                            </DateFnsProvider>
                          </MaterialTableLanguageContextProvider>
                        </LanguageProvider>
                      </WidthContextProvider>
                    </MessageQueueContextProvider>
                  </ThemeContextProvider>
                </TracingHandlerContextProvider>
              </ErrorHandlerContextProvider>
            </GraphqlContextProvider>
          </UserContextProvider>
        </AuthContextProvider>
      </UrlContextProvider>
    </BrowserContextProvider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
