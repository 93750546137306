import React, { createContext, useContext } from "react";
import { Ticket, CrewResource } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getCrewResourceGraphqlMutationOptions, MUTATE_CREW_RESOURCE } from "./crewResourceMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";
import { useMessageQueueContext } from "../../messageQueue/messageQueueContext";

export interface CrewResourceMutationsContext {
    mutateCrewResource: (crewResource: CrewResource, onTicketSubscribed: (documentId: Guid, variables: any) => void) => Promise<void>;
}

const CrewResourceMutationsContext = createContext<CrewResourceMutationsContext>(null as unknown as CrewResourceMutationsContext);

export const CrewResourceMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const messageQueueContext = useMessageQueueContext();
    const [crewResourceMutation] = useMutation(MUTATE_CREW_RESOURCE);

    const handleMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateCrewResource.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
    }

    const mutateCrewResource = async (crewResource: CrewResource, onTicketSubscribed: (documentId: Guid, variables: any) => void): Promise<void> => {
        const options = getCrewResourceGraphqlMutationOptions(crewResource);
        try {
            const result = await crewResourceMutation(options);
            return handleMutationResult(result, options.variables, onTicketSubscribed);
        } catch (error) {
            messageQueueContext.addMessage({})
            throw error;
        }
    }

    const crewResourceMutationsContext: CrewResourceMutationsContext = {
        mutateCrewResource
    };

    return (
        <CrewResourceMutationsContext.Provider value={crewResourceMutationsContext}>
            {children}
        </CrewResourceMutationsContext.Provider>
    );
}

export const useCrewResourceMutationsContext = (): CrewResourceMutationsContext => {
    return useContext(CrewResourceMutationsContext);
}
