import { useEffect, useState } from "react";
import { Collapse, IconButton } from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error';
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { useMessageQueueContext } from "../../contexts/messageQueue/messageQueueContext";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useLanguageContext } from "../../contexts/language/LanguageContext";

type props = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
  })
);

export const MutationResultSnackbar: React.FC<props> = ({}) => {
  const messageQueueContext = useMessageQueueContext();
  const languageContext = useLanguageContext();
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<{ message?: string; severity?: "error" | "warning" | "info" | "success" | undefined }>();

  useEffect(() => {
    if (messageQueueContext.messages.length > 0 && !open) {
      const message = messageQueueContext.messages[0];
      setMessage(message);
      setOpen(true);
      messageQueueContext.shiftMessage();
    }
  }, [messageQueueContext.messages, open]);

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          color="error"
          icon={<ErrorIcon />}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message?.message ?? languageContext.getMessage("savingFailed")}
        </Alert>
      </Collapse>
    </div>
  );
};

export default MutationResultSnackbar;
