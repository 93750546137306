import React, { createContext, useContext } from "react";
import { Invoice, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getInvoicesGraphqlMutationOptions, MUTATE_INVOICE } from "./invoiceMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";
import { useMessageQueueContext } from "../../messageQueue/messageQueueContext";

export interface InvoiceMutationsContext {
    mutateInvoice: (accout: Invoice, onTicketSubscribed: (documentId: Guid, variables: any) => void) => void;
}

const InvoiceMutationsContext = createContext<InvoiceMutationsContext>(null as unknown as InvoiceMutationsContext);

export const InvoiceMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const messageQueueContext = useMessageQueueContext();
    const [invoiceMutation] = useMutation(MUTATE_INVOICE);

    const handleInvoiceMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateInvoice.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
    }

    const mutateInvoice = (invoice: Invoice, onTicketSubscribed: (documentId: Guid, variables: any) => void): void => {
        const options = getInvoicesGraphqlMutationOptions(invoice)
        invoiceMutation(options).then((result) => handleInvoiceMutationResult(result, options.variables, onTicketSubscribed)).catch((error) => messageQueueContext.addMessage({}));
    }

    const invoiceMutationsContext: InvoiceMutationsContext = {
        mutateInvoice
    };

    return (
        <InvoiceMutationsContext.Provider value={invoiceMutationsContext}>
            {children}
        </InvoiceMutationsContext.Provider>
    );
}

export const useInvoiceMutationsContext = (): InvoiceMutationsContext => {
    return useContext(InvoiceMutationsContext);
}
