import { LanguageMap } from "../interfaces";

export const enMap: LanguageMap = {
  ok: "OK",
  about: "About",
  account: "Account",
  accountType: "Account Type",
  accountBudget: "Account budget",
  accountDetails: "Account details",
  accountGroup: "Account group",
  changeOrderGroup: "Group",
  accountInvoicedAmount: "Invoiced amount: Account",
  accountList: "Accounts",
  accountNumber: "Account number",
  accountProcurement: "Account procurement",
  accounts: "Accounts",
  accountTransactionList: "Transactions",
  action: "Action",
  accessControl: "Access Control",
  ACTIVE: "Active",
  addAccount: "Add Account",
  addInvoice: "Add Invoice",
  addContract: "Add Contract",
  adjustment: "Adjustment",
  adjustments: "Adjustments",
  accumulated: "Accumulated",
  all: "All",
  amount: "Amount",
  withheldAmount: "Withheld Amount",
  quantity: "Quantity",
  analytics: "Analytics",
  analysis: "Analysis",
  analyticsView: "This is analytics",
  and: "and",
  expectedDate: "Expected Date",
  answeredDate: "Answered Date",
  approved_plural: "Approved",
  not_approved_plural: "Disapproved",
  approved: "Approved",
  rejected: "Rejected",
  approvedCost: "Approved cost",
  ASSISTANT_PROJECT_MANAGER: "Assistant project manager",
  accrual: "Accrual",
  accumulatedAccruedPosted: "Accumulated Accrued Posted",
  receivedRegistered: "Received - Registered",
  revisedForecastIsAboveAccountForecast: "Revised forecast is above account forecast",
  revisedForecastIsAboveConstructionAdministrationCosts: "Revised forecast is above construction administration budget",
  producedNotBilled: "Produced - Not Billed",
  accumulatedProduced: "Accumulated Produced Value",
  constructionCosts: "Construction Costs",
  constructionStart: "Construction Start",
  administrationCosts: "Administration Costs",
  producedPercentageOfForecast: "Produced % of Forecast",
  producedPercentageOfFinalForecast: "Produced % of Final Forecast",
  sumProducedValue: "Sum Produced Value",
  surcharge: "surcharge",
  balance: "Balance",
  billed: "Billed",
  budget: "Budget",
  burndown: "Burndown",
  cancel: "Cancel",
  change: "Change",
  changeOrder: "Change Order",
  changeOrderBilled: "Cost",
  changeOrderBudget: "Change order budget",
  changeOrderProcurement: "Change order procurement",
  changeOrderShort: "CO",
  changeOrders: "Change Orders",
  quantityChangeOrders: "Change Order Quantity",
  accrualAndOperations: "Accrual And Operations",
  changes: "Changes",
  clear: "Clear",
  close: "Close",
  columnActorCount: "Actors",
  columnName: "Name",
  columnOwnerCount: "Owners",
  columnStatus: "Status",
  comment: "Comment",
  comments: "Comments",
  completion: "Completion",
  company: "Neate AS",
  companySite: "https://neate.no/",
  consequence: "Consequence",
  contract: "Contract",
  contractBudget: "Contract Budget",
  contractor: "Contractor",
  contractOrChange: "Contract/Change",
  contractProcurement: "Contract procurement",
  contracts: "Contracts",
  contractCosts: "Contract Costs",
  contractDetails: "Contract details",
  constructionAdministrationBudget: "Construction Administration Budget",
  cost: "Cost",
  costs: "Costs",
  costWithSurcharge: "Cost with surcharge",
  costRequirements: "Cost Requirements",
  created: "Created",
  crew: "Crew",
  crewList: "Crewlist",
  currentLanguage: "English",
  dark: "Dark",
  date: "date",
  days: "Days",
  delete: "Delete",
  description: "Description",
  detailed: "Detailed",
  details: "Details",
  scannedDate: "Scanned Date",
  voucherDate: "Voucher Date",
  doYouWantToDeleteFile: "Do you want to delete the file?",
  doYouWantToDeleteProject: "Do you want to delete the project?",
  doYouWantToDeleteAccount: "Do you want to delete the account?",
  doYouWantToDeleteInvoice: "Do you want to delete the invoice?",
  doYouWantToDeleteContract: "Do you want to delete the contract?",
  doYouWantToDeleteChangeOrder: "Do you want to delete the change order?",
  doYouWantToDeleteExpectedChange: "Do you want to delete the expected change?",
  doYouWantToDeleteRisk: "Do you want to delete the risk?",
  doYouWantToDeleteElement: "Do you want to delete the element?",
  downloadForecastReport: "Download forecast report",
  dueDate: "Due Date",
  interimAccountedDate: "Accounted date",
  interimAccountedAmount: "Accounted amount",
  email: "Email",
  edit: "Edit",
  editProject: "Edit project",
  editAccount: "Edit account",
  editAccountGroup: "Edit account group",
  editContract: "Edit contract",
  editChangeOrder: "Edit change order",
  editChangeOrderGroup: "Edit group",
  newInvoice: "New invoice",
  editInvoice: "Edit invoice",
  childInvoice: "Sub Invoice",
  parentInvoice: "Top Invoice",
  enableProgressConsequence: "Enable progress consequence",
  externalName: "External name",
  expectation: "Expectation",
  enabled: "Enabled",
  finished: "Finished",
  final: "Final",
  finalForecast: "Final Forecast",
  finalForecasts: "Final Forecasts",
  forecastReport: "Forecast Report",
  forecast: "Forecast",
  forecasts: "Forecasts",
  changesInForecastLastMonth: "Changes in forecast from last month",
  from: "From",
  fromDate: "From date",
  group: "Group",
  groupDetails: "Group Details",
  general: "General",
  global: "Global",
  hello: "Neate demo",
  home: "Home",
  homeView: "This is home",
  HSE_MANAGER: "HSE manager",
  id: "Id",
  INACTIVE: "inactive",
  info: "Info",
  interim: "Interim",
  incoming: "Incoming",
  includeContractProcurementInForecast: "Use contract procurement in forecast?",
  warningContractProcurementIsLargerThenBudget: "Contract procurement is larger then contract budget, and will be used to estimate the forecast.",
  invoice: "Invoice",
  invoiceComplete: "Complete",
  invoiceRejected: "Rejected",
  invoicedAmount: "Invoiced",
  remainingInvoicedAmount: "Remaining amount",
  invoicedAmountIsGreaterThanProcurment: "Invoiced amount is greater than procurement",
  invoiceIsPartOfAnotherInvoice: "Invoice is part of another invoice",
  invoiceIsSplitIntoMultipleInvoicesAndCanNotBeEdited: "Invoice is split into multiple invoices and can not be edited",
  invoiceMissingComment: "Incomplete",
  invoiceNumber: "Invoice number",
  invoices: "Invoices",
  importAccounts: "Import accounts",
  ITB_MANAGER: "ITB responsible",
  landlord: "Landlord",
  landlordShort: "Landlord",
  language: "Language",
  last: "Last",
  light: "Light",
  list: "List",
  listHeading: "Case List",
  listView: "This is list",
  inTotal: "In total",
  login: "Login",
  logout: "Logout",
  max: "Max",
  missingComment: "Missing comment",
  month: "Month",
  monthly: "Monthly",
  monthlySalary: "Monthly Salary Costs",
  monthlyPercentage: "Monthly Percentage",
  aMonth: "Month",
  name: "Name",
  netAccountBudget: "Rem. Account Budget",
  networkError: "Network error: Unable to contact server",
  new: "New",
  newProject: "New project",
  newAccount: "New account",
  newContract: "New contract",
  none: "None",
  showNonInterimAccountedInvoices: "Non processed invoices exists!",
  showInterimAccountedInvoices: "Show processed invoices",
  showPostedInvoices: "Show posted invoices",
  showInvoices: "Show invoices",
  notApproved: "Not approved",
  notProcessed: "Not processed",
  notSelected: "Not selected",
  notSpecified: "Not specified",
  selectProject: "Select project",
  selectTenant: "Enter your organization",
  wrongTenant: "Organization does not exist!",
  reader: "Reader",
  writer: "Writer",
  admin: "Admin",
  owner: "Owner",
  administration: "Administration",
  normal: "Normal",
  OPPORTUNITY: "Opportunity",
  opportunityAdjustments: "Opportunity Adjustments",
  outgoing: "Outgoing",
  overview: "Overview",
  payments: "Payments",
  probability: "Probability",
  PROBABILITY: "Probability",
  procurement: "Procurement",
  productName: "Aimz",
  profile: "Profile",
  project: "Project",
  projectInformation: "Project Information",
  projectList: "Projects",
  projectStart: "Project start",
  projectEnd: "Project end",
  projectName: "Project name",
  projectNumber: "Project number",
  projectCode: "Project code",
  projectCodes: "Project codes",
  accountCode: "Account code",
  accountCodes: "Account codes",
  property: "Property",
  progressConsequence: "Progress Consequence",
  per: "per",
  percentage: "Percentage",
  percentShare: "Percent Share",
  receivedDate: "Received Date",
  sentDate: "Sent Date",
  remainingAccountBudget: "Remaining account budget",
  remainingAmount: "Remaining amount",
  remainingWithheldAmount: "Remaining withheld amount",
  remainingCost: "Remaining billed",
  remainingExpectedCost: "Remaining cost",
  remainingContractBudget: "Remaining contract budget",
  remainingContractProcurement: "Remainig Contract procurement",
  remainingProcurement: "Remaining procurement",
  reset: "Reset",
  revised: "Revised",
  revisedForecast: "Revised Forecast",
  revisedAccountBudget: "Revised Account Budget",
  RISK: "Risk",
  riskAdjustments: "Risk Adjustments",
  role: "Role",
  salary: "Salary",
  save: "Save",
  saving: "Saving",
  saveInvoice: "Save Invoice",
  settings: "Settings",
  signedInAs: "Signed in as:",
  signIn: "Sign in",
  signOut: "Sign out",
  SITE_MANAGER: "Site manager",
  splitInvoice: "Split invoice",
  starting: "Starting",
  state: "State",
  subContractor: "Subcontractor",
  subContractorShort: "Subcontractor",
  subContractorCosts: "Subcontractor Costs",
  subProjects: "Sub projects",
  counterClaim: "Counter Claim",
  counterClaimShort: "CC",
  newCounterClaim: "New Counter Claim",
  sum: "Sum",
  sumChangeOrderCost: "Cost: change order",
  sumChangeOrderInvoicedAmount: "Invoiced amount: change order",
  sumContractBudget: "Sum contract budget",
  sumContractInvoicedAmount: "Invoiced amount: contract",
  sumContractProcurement: "Sum contract procurement",
  sumChangeOrderRemainingCost: "Remaining invoice amount: change order",
  sumRemainingContractBudget: "Sum remaining contract budget",
  contractCostScope: "Total contract procurement",
  sumExpenseForecasts: "Sum expenses",
  sumContractCostScope: "Sum contract cost scope",
  IncurredCosts: "Incurred costs",
  simulating: "Simulating",
  forecastWithSurcharge: "Forecast With Surcharge",
  totalSurcharge: "Total Surcharge",
  supplier: "Supplier",
  theme: "Theme",
  this: "This",
  time: "Time",
  title: "Title",
  to: "To",
  toDate: "To date",
  today: "Today",
  toggle: "Toggle",
  totalCosts: "Total costs",
  transactions: "Transactions",
  type: "Type",
  uploadImage: "Upload image",
  downloadImage: "Download image",
  uncertainty: "Uncertainty",
  unkown: "Unkown",
  unprocessed: "Incomplete",
  unforeseen: "Unforeseen",
  adjustmentsRisksAndUnforeseen: "Adjustments",
  up: "Up",
  expenseItem: "Expense Item",
  remainsToDistribute: "Remains to distribute",
  newExpectedChange: "New Expected Change",
  expectedChange: "Expected Change",
  expectedPostedExpense: "Expected posted expense",
  expectedAccumulatedPostedExpense: "Expected accumulated posted expense",
  actuallyPostedExpense: "Actually posted expense",
  actuallyAccumulatedPostedExpense: "Actually accumulated posted expense",
  usedWithForecast: "Used with forecast",
  useRemainingAmount: "Use remaining amount",
  username: "Username",
  value: "Value",
  variance: "Variance",
  week: "Week",
  with: "with",
  yes: "Yes",
  yesterday: "Yesterday",
  next: "Next",
  back: "Back",
  document: "Document",
  QUANTITIES: "Quantities",
  PROJECT_CHIEF: "Project Leader",
  PROJECT_MANAGER: "Project Manager",
  PROJECT_MANAGER_ASSISTANT: "Project Manager Assistant",
  CONSTRUCTION_MANAGER: "Construction Manager",
  OPERATIONS_MANAGER: "Operations Manager",
  PROJECT_SECRETARY: "Project Secretary",
  ENVIRONMENTAL_MANAGER: "Environmental Manager",
  HEALTH_SAFETY_AND_ENVIRONMENT_LEADER: "HSE-leader",
  ENGINEERING_MANAGER: "Engineering Manager",
  TECHNICAL_ENGINEERING_MANAGER: "Technical Engineering Leader",
  INTEGRATED_TECHNICAL_BUILDING_INSTALLATIONS_RESPONSIBLE: "ITB-responsible",
  CONTROLLER: "Controller",
  PURCHASER: "Purchaser",
  OTHER: "Other",
  disabled: "Disabled",
  showAllMonths: "Show All Months",
  selectFile: "Select file",
  noFileSelected: "No file selected",
  uploadCsvFileWithAccountDefinitions: "Upload csv file with account definitions.",
  exampleFileMayBeDownloadedFromHere: "Example file may be downloaded from here:",
  yearlyWageGrowth: "Yearly wage growth",
  monthForWageGrowth: "Month for wage growth",
  yearForWageGrowth: "Start year for wage growth",
  flags: "Flags",
  UNKNOWN_VAT: "Unknown VAT",
  UNKNOWN_CURRENCY: "Unknown currency",
  EXTERNAL_ID_MISSING_WITH_PROVIDER: "Invoice deleted from external invoice system",
  youHaveAdded: "You have added",
  someFilesCouldNotBeImported: "Some files could not be imported.",
  allFilesWhereSuccessfullyImported: "All files where successfully imported!",
  filesAreStillUploading: "Files are still uploading. Are you sure you want to exit?",
  couldNotImportFile: "Could not import file",
  dueTo: "due to",
  dragAndDropFiles: "Drag and drop documents or images here or click to add",
  uploadFiles: "Upload files",
  dropFiles: "Drop files",
  importingFiles: "Importing files",
  importFinished: "Import finished",
  routeInformation: "Route information",
  chosen: "Chosen",
  choices: "Choices",
  selected: "Selected",
  files: "Files",
  file: "File",
  confirm: "Confirm",
  invoicedTotal: 'Invoiced total',
  payedOut: 'Payed out',
  whereAsWithheld: 'Where as witheld',
  notes: 'Notes',
  notesSaved: 'Notes saved',
  updated: 'Updated',
  forecastLong: 'Final forecast',
  summary: 'Summary',
  showForecastOnly: 'Show Forecast Only',
  changedBy: 'Changed by',
  report: 'Report',
  APPROVED: 'Approved',
  NOT_APPROVED: 'Not approved',
  NOT_PROCESSED: 'Not processed',
  changeLog: 'Changelog',
  changeType: 'Change type',
  timeStamp: 'Timestamp',
  documentType: 'Document type',
  CHANGE: 'Changed',
  CREATE: 'Created',
  PROJECT: 'Prosjekt',
  ACCOUNT: 'Account',
  CONTRACT: 'Contract',
  INVOICE: 'Invoice',
  CHANGE_ORDER_SUB_CONTRACTOR: 'Change order subcontractor',
  CHANGE_ORDER_LANDLORD: 'Change order landlord',
  CHANGE_ORDER_EXPECTED: 'Change order expected',
  CHANGE_ORDER_COUNTER_CLAIM: 'Counterclaim',
  INTERIM: 'Interim',
  CREW_LIST: 'Crew list',
  CREW_RESOURCE: 'Crew resource',
  ACCRUAL_AND_OPERATIONS: 'Accrual and operations',
  ACCRUAL_AND_OPERATIONS_EXPENSES: 'Accrual and operations expense',
  FORECASTS: 'Forecast',
  GENERAL: 'GENERAL',
  contractDescription: 'Contract description',
  NOT_POSTED: 'Not posted',
  POSTED: 'Posted',
  PARTLY_POSTED: 'Partly posted',
  accountId: 'Account',
  contractId: 'Contract',
  postedStatus: 'Posted status',
  NOT_DISTRIBUTED: 'Not distributed',
  DISTRIBUTED: 'Distributed',
  RETURNED: 'Returned',
  REJECTED: 'Rejected',
  approvedStatus: 'Approved status',
  changeOrderComment: 'Comment',
  status: 'Status',
  changeOrderDescription: 'Description',
  answered: 'Answered',
  expected:'Expected',
  useContractProcurementWithForecast: 'Use in forecast',
  sent: 'Sent',
  received: 'Received',
  progressConsequenceEnabled: 'Progress consequence',
  progressConsequenceDescription: 'Progress consequence description',
  defaultCrewResourceDetails: 'Details',
  assignmentPercentage: 'Assigned percentage',
  salaryAdjustmentPercentage: 'Salary adjustment percentage',
  changeOrderExpectedId: 'Change order expected',
  usedInForecast: 'Used in forecast',
  monthlySalaryShort: 'Salary per/month',
  monthlyPercentageShort:'Percentage per/month',
  yearlyWageGrowthShort: 'Salary growth per/year',
  lastChangedBy: 'Last changed by',
  approvedChanges: 'Approved changes',
  totalInvoicedChangeOrder: 'Totalt invoiced change order',
  revisedFinalForecast: 'Revised final forecast',
  revisedForecastIsAboveForecast: 'Revised forecast is larger than forecast',
  variable: 'Variable',
  newValue: 'New value',
  oldValue: 'Previous value',
  system: 'System',
  forecastCalculationInProgress: 'Calculation in progress',
  savingFailed: 'Saving failed',
};
