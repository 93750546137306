import React, { createContext, useContext } from "react";
import { Account, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getAccountsGraphqlMutationOptions, MUTATE_ACCOUNT } from "./accountMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";
import { useMessageQueueContext } from "../../messageQueue/messageQueueContext";

export interface AccountMutationsContext {
    mutateAccount: (account: Account, onTicketSubscribed: (documentId: Guid, variables: any) => void, onFinished?: () => void) => void;
}

const AccountMutationsContext = createContext<AccountMutationsContext>(null as unknown as AccountMutationsContext);

export const AccountMutationsContextProvider: React.FC<{}> = ({ children }) => {
    const messageQueueContext = useMessageQueueContext();
    const ticketContext = useTicketContext();
    const [accountMutation] = useMutation(MUTATE_ACCOUNT);

    const handleAccountMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void, onFinished?: () => void): void => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            if (onFinished) {
                onFinished();
            }
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateAccount.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
        if (onFinished) {
            onFinished();
        }
    }

    const mutateAccount = (account: Account, onTicketSubscribed: (documentId: Guid, variables: any) => void, onFinished?: () => void): void => {
        const options = getAccountsGraphqlMutationOptions(account);
        accountMutation(options).then((result) => handleAccountMutationResult(result, options.variables, onTicketSubscribed, onFinished)).catch((error) => messageQueueContext.addMessage({}));
    }

    const accountMutationsContext: AccountMutationsContext = {
        mutateAccount
    };

    return (
        <AccountMutationsContext.Provider value={accountMutationsContext}>
            {children}
        </AccountMutationsContext.Provider>
    );
}

export const useAccountMutationsContext = (): AccountMutationsContext => {
    return useContext(AccountMutationsContext);
}
